.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.my-title:hover{
  cursor: pointer;
}
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800;900&family=Kumbh+Sans:wght@400;500;600;700;800&display=swap");
.mean-container .mean-nav ul li > a.mean-expand i, .tp-product-tab-2 .nav-tabs .nav-link .tp-product-tab-tooltip, .tp-tab-line, .tp-accordion .accordion-button .tp-accordion-plus, .tp-accordion .accordion-button .tp-accordion-plus::after, .tp-accordion .accordion-button .tp-accordion-plus::before, .tp-swiper-dot .swiper-pagination-bullet, .tp-swiper-dot-border .swiper-pagination-bullet, .tp-swiper-scrollbar, .back-to-top-wrapper, a,
button,
p,
input,
select,
textarea,
li,
.transition-3 {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.tp-hotspot svg, .tp-pagination ul li a svg, .tp-pagination ul li span svg, .tp-swiper-arrow button svg, .tp-btn svg, .tp-theme-toggle-main span svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.tp-section-title-pre-bg {
  font-family: var(--tp-ff-abril);
}

/* transform */
.tp-product-tab-2 .nav-tabs .nav-link:not(:first-child)::after {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.disabled{
  pointer-events: none;
  opacity: 0.4;
}
.servicereg{
  font-size: 1.em;
}
.servicereg input{
  font-size: 1.2em;
  height: 45px;
}
.servicereg button{
  height: 35px;
  font-size: 1em;
  background-color: #1d3461;
  border-color: #ffff;
}
.servicereg .tp-faq-tab-content{
  padding: 5%;
}
.trainingreg{
  /* position: absolute; */
  /* content: ""; */
  /* top: 0;
  left: 0; */
  width: 100vw;
  /* height: 73%; */
  min-height: 100vh;
  /* z-index: -1; */
  background: var(--tp-gradient-primary);
  background-attachment: fixed;
  background-position: cover;
  /* padding-top: 2%;*/
  padding-bottom: 2%; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.servicepay .tp-faq-tab-content{
  padding: 5%;
  font-size: 1.em;
}

.servicedetails .card{
  padding: 5%;
  font-size: 1.em;
  text-align: justify;
}
.payment-method {
  border-bottom: solid 2px rgb(247, 243, 243);
}

.payment-method button{
  cursor: pointer;
  border-radius: 0;
  padding: 0;
}
.servicepay .payment-form{
  padding-top: 5%;
  font-size: 1.em;
}
.servicepay input {
  height: 45px;
  font-size: .9em;
  font-style: none;
}
.payment-form .method-desc{
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 1.em;
}
.payment-form button{
  margin-top: 10px;
  width: 50%;
  height: 35px;
  font-size: 1em;
  background-color: #1d3461;
  border: none;
}
input::placeholder{
  font-size: 1.em;
}
button:disabled{
  border-style: none;
}
button:hover{
  background: #1d3461;
}
.prod-logo{
  height: 90px;
  width: 80px;
}
.training-amount{
  text-align: right;
  font-weight: 700;
  /* color: green; */
}
.prodtitle{
  border-bottom: solid 2px rgb(247, 243, 243);
}

.total-amount{
  margin-top: 5%;
  font-weight: 800;
  border-top: solid 2px rgb(247, 243, 243);
}
.centerHV{
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-btn{
  /* margin-bottom: 2px; */
  margin-right: 10px;
  height: 50px;

}
.selected-btn{
  border-bottom: solid 2px #1d3461;
  /* border: solid 2px #1d3461; */
  padding: 25%;
  padding: 10px;
  /* background: rgb(243, 245, 247); */
  box-shadow: 2px 2px 2px 2px rgb(247, 243, 243);
}
.selected-btn .img-btn{
  height: 40px;
  width: 60px;
}
.img-btn{
  width: 50px;
  height: 30px;
  margin-top: 5px
}
.form-btn:hover{
  background: #ffff;
  color: #1d3461;
}
.form-btn{
  border: solid 1px #1d3461;
}

.swal2-popup { font-size: 9pt !important; }
/* .swal2-popup button{
  height: 30px;
  width: 50px;
  font-size: 9pt;
  display: flex;
  align-items: center;
  justify-content: center;

} */
.swal2-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962B3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
/* .swal2-modal {
  background-color: rgba(63,255,106,0.69);
  border: 1px solid #1d3461;
} */